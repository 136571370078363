/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS201P
 * 화면 설명: 설계청약 현황 > 신계약진행 공통 검색 팝업
 */
 <template>
  <ur-page-container class="msp" :title="pPopupTitle" :show-title="true" type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignH="start" componentid="" direction="row" class="form-style-type-1">
          <!-- 조건 선택 -->
          <msp-bottom-select :items="PSConstants.PLAN_OFR_SEARCH_OPT_LIST" 
            :itemValue="'key'" 
            :itemText="'label'" 
            v-model="searchScVal" 
            underline class="mr10" 
            placeholder="고객명" 
            bottom-title="상세조건 선택" 
            closeBtn 
            @selectedItem="fn_SelectedItemHandler"/>         

           <!-- 키워드 입력 keyType inputmode-->
            <mo-text-field ref="refKeyword" :type="keyType"  :maxLength="keywordMaxLength" :mask="keywordInType" v-model="searchKeyword" class="form-input-name" underline clearable placeholder="입력하세요." @keyup.enter="fn_Search"/> 
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>            
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isSearchBtnDisabled" @click="fn_Search">조회</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSConstants from '@/config/constants/psConstants'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import Msg from '@/systems/webkit/msg/msg'
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객조회

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name      : 'MSPPS201P', 
  screenId  : 'MSPPS201P', 

  components: {
      'MSPCM129P' : MSPCM129P //고객조회 팝업
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pPopupTitle     : {type : String,  default: () => '검색'  }, //팝업 타이틀
    pParntSrnId     : {type : String,  default: () => ''  }, // 부모 화면 ID
    pSearchKeyword  : {type : String,  default: () => ''  }, // Default set Keyword Value
    pSearchScVal    : {type : String,  default: () => '1'  }  //1:고객명 2:상품명 3:영수증번호
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      PSConstants         : PSConstants,  //PS상수
      searchKeyword       : '', // Default set Keyword Value
      keyType             : 'none', //키패드 타입 none , number
      keywordInType       : null, //keyword input type  
      keywordMaxLength    : 10 , //keyword 입력길이
      searchScVal         : '', //1:고객명 2:상품명 3:영수증번호
      isSearchBtnDisabled : false, // 조회버튼 비활성 여부
      popup129            : {}, // MSPCM129P 소개자 고객조회 팝업
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    
    //받은 파라미터 셋팅
    this.pPopupTitle    = this.pPopupTitle    ? this.pPopupTitle.trim() : '검색'     
    this.pParntSrnId    = this.pParntSrnId    ? this.pParntSrnId.trim() : '' // 부모 화면 ID
    this.searchKeyword  = this.pSearchKeyword ? this.pSearchKeyword.trim() : '' // Default set Keyword Value
    this.searchScVal    = this.pSearchScVal   ? this.pSearchScVal : '1'; //1:고객명 2:상품명 3:영수증번호

    
    this.fn_SetKeywordInputTp(this.searchScVal)

    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      this.fn_Clear()
      let rtnData = {
        parntSrnId    : this.pParntSrnId , // 부모 화면 ID
        searchScVal   : this.searchScVal , // 1:고객명 2:상품명 3:영수증번호
        searchKeyword : this.searchKeyword // 입력된 키워드 값
      }
      this.$emit('onPopupSearch',rtnData) // 초기화 검색 적용
    },
    
    /******************************************************************************
    * Function명  : fn_Search
    * 설명        : 조회 액션
    ******************************************************************************/
    fn_Search () {
      
      this.searchKeyword = this.searchKeyword? this.searchKeyword.trim() : ''

      let rtnData = {
        parntSrnId    : this.pParntSrnId , // 부모 화면 ID
        searchScVal   : this.searchScVal , // 1:고객명 2:상품명 3:영수증번호
        searchKeyword : this.searchKeyword // 입력된 키워드 값
      }

      // 고객명일때 검색팝업 호출
      if (this.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key && this.searchKeyword !== '') {
        this.fn_OpenMSPCM129P()
      }
      else {
        this.$emit('onPopupSearch',rtnData)
      }
    },// fn_Search

    /******************************************************************************
    * Function명  : fn_Clear
    * 설명        : 초기화
    ******************************************************************************/
    fn_Clear () {
      this.searchKeyword = ''
    },// 마지막 메서드 함수 , 없음 주의

    /******************************************************************************
    * Function명  : fn_SelectedItemHandler
    * 설명        : 옵션 변경 이벤트 핸들러
    ******************************************************************************/
    fn_SelectedItemHandler(item){
      this.fn_Clear()
      this.fn_SetKeywordInputTp(item.key)
    },

    fn_SetKeywordInputTp(scVal){
      //this.$refs.refKeyword
      if(scVal == PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key){ //영수증번호
        this.keyType = 'number'
        this.keywordInType = '####################' //숫자20자리 
        this.keywordMaxLength = 20
      }else if(scVal == PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key){ // 고객명
        this.keyType = 'none'
        this.keywordInType = null // 모두 입력 가능
        this.keywordMaxLength = 10
      }else{
        this.keyType = 'none'
        this.keywordInType = null // 모두 입력 가능
        this.keywordMaxLength = 20
      }
    },

    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM129P
    * 설명       : 소개자 조회 검색 팝업창에서 '확인' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_OpenMSPCM129P (param) {
      let lv_Vm = this
      this.popup129 = lv_Vm.$bottomModal.open(MSPCM129P, {
        properties: {
          pCustNm: lv_Vm.searchKeyword // 고객명 파라미터
        },
        listeners: {
          onPopupConfirm: (pData) => {
            // 파라미터 세팅
            let rtnData = {
              parntSrnId    : this.pParntSrnId , // 부모 화면 ID
              searchScVal   : PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key, // 1:고객명 2:상품명 3:영수증번호
              searchKeyword : pData.custNm, // 고객명
              chnlCustId    : pData.chnlCustId // 채널 고객ID
            }
            this.$emit('onPopupSearch',rtnData)

            lv_Vm.$bottomModal.close(lv_Vm.popup129)
          },
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popup129)
          }
        }
      },
      {
        properties : {noHeader : true}
      })
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>