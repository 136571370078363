/*
 * 업무구분 : 전자서명
 * 화면 명 : MSPPS242P
 * 화면 설명: 설계청약 현황 > 신계약 진행 현황
             AS-IS TSSPS213P
 */
<template>
  <ur-page-container title="진행현황" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="mt30 pl24 pr24">

        <div class="ing-content">

         <div v-for="(item,index) in listData" :class="[item.isDone ? 'ing-item mb12 completed' : 'ing-item mb12']" :key="index">
            <!--타이틀-->
            <span class="list-step fs14rem dsInline min70">{{item.data1}}</span> 
            <!--완료일자-->
            <span class="list-step fs14rem dsInline min80">{{item.date}}</span>
           
            <!--상세내용-->
            <span v-if="item.pendingStep" :class="[(item.data3)? 'list-step fs14rem crTy-orange2 underline':'list-step fs14rem crTy-orange2']" @click="fn_AlertConts(item)">{{item.data2}}</span>

            <span v-else class="list-step fs14rem crTy-orange2">{{item.status}}</span>

         </div>

          <!--
            date 완료일자
            data1 // 타이틀
            status 상태 - 완료, 미심사, 해당없음 등등등
            data2 상세내용 
            data3 상세내용
            isDone true 완료 false 미진행 완료가 되었는지 여부
            isStep  // 7단계 스탭에 포함되는지 여부 (청약접수, 입금, 신계약심사, 적부, 최종결과, 서비스콜, 증권발행)
          -->

          <!--
          <div class="ing-item mb12 completed">
            <span class="list-step fs14rem dsInline min70">청약접수</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
          </div>

          <div class="ing-item mb12 completed">
            <span class="list-step fs14rem dsInline min70">입금</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
          </div>

          <div class="ing-item mb12 completed">
            <span class="list-step fs14rem dsInline min70">신계약심사</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
            <span class="list-step fs14rem crTy-orange2">미결</span>
          </div>

          <div class="ing-item mb12 completed">
            <span class="list-step fs14rem dsInline min70">미결1차</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
            <span class="list-step fs14rem crTy-orange2">건강검진대상</span>
          </div>

          <div class="ing-item mb12">
            <span class="list-step fs14rem dsInline min70">적부</span>
          </div>

          <div class="ing-item mb12 completed">
            <span class="list-step fs14rem dsInline min70">최종결과</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
          </div>

          <div class="ing-item mb12">
            <span class="list-step fs14rem dsInline min70">서비스콜</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
          </div>

          <div class="ing-item mb12 completed">
            <span class="list-step fs14rem dsInline min70">증권발행</span>
            <span class="list-step fs14rem dsInline min80">2023-01-02</span>
          </div>
        -->  
        </div>

      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPPS242P',
  screenId: 'MSPPS242P',
  
  components: {
    
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    // 데이터 조회
    this.fn_ServiceData(this.contNo, 'txTSSPS22S2') // 서비스콜 I/F 호출
  },
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  computed: {
  },
  watch: {
  },
  /***********************************************************************************
  * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    contNo: {type: String}
  },

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      listData: [], // 리스트 데이터
      srvclExistYn: '', // 서비스콜 존재여부
      phclYmd: '', // 통화일자
      isNodata: false // 데이터 없음 Flag
    }
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      this.fn_Close()
    },

    /******************************************************************************
    * Function명  : fn_AlertConts
    * 설명        : 미결 상태일때 상세 Alert 표시
    ******************************************************************************/
    fn_AlertConts(item) {
      if(! (item.pendingStep && item.data3)) return
      this.getStore('confirm').dispatch('ADD', item.data3)
    },

    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의( 신계약진행현황 )
    ******************************************************************************/
    fn_ServiceData (pId, tId) {
      let pParams = {}
      pParams.srnId = this.$options.screenId // 대표 화면 명
      pParams.data = {}
      pParams.appId = 'sli.ipi'
      pParams.fnctScCd = 'S'
      pParams.trnstId = tId
      if (tId === 'txTSSPS22S2') { // 서비스콜
        pParams.serviceId = 'C339_F10670059_S'
        pParams.reqVONm = 'sli.in.ea2.vo.f1067.EAF10670059ReqVO'
        pParams.resVONm = 'sli.in.ea2.vo.f1067.EAF10670059ResVO'
        pParams.data.contNo = pId
      } else if (tId === 'txTSSPS22S1') { // 신계약 진행현황
        pParams.serviceId = 'C339_F1PQL0346_S'
        pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0346VO'
        pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0346VO'
        pParams.data.zNcontTrtStatMobIfDto = {}
        pParams.data.zNcontTrtStatMobIfDto.contNo = pId
      }
      if (pParams.serviceId && pParams.serviceId !== '') {
        PSServiceManager.invokeSecuMskng(pParams, this.fn_ServiceResultHandler, tId, this.fn_ServiceFaultHandler)
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler (event, tId) {
      let lv_data = event.data

      if (tId === 'txTSSPS22S2') { // 서비스콜
        if (lv_data) {
          if (lv_data.phclYmd) {
            this.phclYmd = lv_data.phclYmd // 통화일자
          }
          if (lv_data.srvclExistYn) {
            this.srvclExistYn = lv_data.srvclExistYn // 서비스콜 존재여부
          }
        }
        this.fn_ServiceData(this.contNo, 'txTSSPS22S1') // 신계약 진행현황 호출
      } else if (tId === 'txTSSPS22S1') { // 신계약 진행현황
        if (lv_data && lv_data.zncontTrtStatMobIfDto) {
          this.fn_CustomListData(lv_data.zncontTrtStatMobIfDto)
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler (event, tId) {
      if (tId === 'txTSSPS22S2') {
        this.fn_ServiceData(this.contNo, 'txTSSPS22S1') // 신계약 진행현황 호출
      } else {
        this.getStore('confirm').dispatch('ADD', event.msgDesc)
      }
    },
    /************************************************************************************************
     * Function명  : fn_CustomListData
     * 설명        : 서비스 호출 결과 데이터로 화면 셋팅
     ************************************************************************************************/
    fn_CustomListData (zncontTrtStatMobIfDto) {
      let d = zncontTrtStatMobIfDto
      let tmpStatus = ''
      let o = null

      this.listData = []
      
      // 청약접수
      o = this.fn_dataMake1(d.trtYmd03, '청약접수', '', '', d.prgStatCd, true)
      this.listData.push(o)
      // 입금
      tmpStatus = (d.aftRctmFg === 'Y') ? '해당없음' : '' // 후입금 Y 일때 해당없음 표시
      o = this.fn_dataMake1(d.trtYmd04, '입금', '', tmpStatus, d.prgStatCd, true)
      this.listData.push(o)

      // 신계약심사
      o = this.fn_dataMake1(d.trtYmd06, '신계약심사', '', d.note06, d.prgStatCd, true)
      this.listData.push(o)

      // 미결1차
      if (d.trtYmd07 && d.trtYmd07 !== '') {
        o = this.fn_dataMake1(d.trtYmd07, '미결1차', d.note07, '', d.prgStatCd, false)
        o = strTurcate(o)
        o.pendingStep = '1' // 미결 1차 2차 3차 구분
        this.listData.push(o)
      }
      
      // 미결2차
      if (d.trtYmd08 && d.trtYmd08 !== '') {
        o = this.fn_dataMake1(d.trtYmd08, '미결2차', d.note08, '', d.prgStatCd, false)
        o = strTurcate(o)
        o.pendingStep = '2' // 미결 1차 2차 3차 구분
        this.listData.push(o)
      }

      // 미결3차
      if (d.trtYmd09 && d.trtYmd09 !== '') {
        o = this.fn_dataMake1(d.trtYmd09, '미결3차', d.note09, '', d.prgStatCd, false)
        o = strTurcate(o)
        o.pendingStep = '3' // 미결 1차 2차 3차 구분
        this.listData.push(o)
      }
      
      // 적부
      // tmpStatus = (d.trtYmd13 !== '' && d.trtYmd14 !== '' && d.trtYmd15 !== '') ? '' : '해당없음'
      // this.listData.push(this.fn_dataMake1(d.trtYmd15, '적부', '', tmpStatus, d.prgStatCd, true, true))
      o = this.fn_dataMake1(d.trtYmd15, '적부', '', '', d.prgStatCd, true) 
      this.listData.push(o)
      
      // 최종결과
      o = this.fn_dataMake1(d.trtYmd17, '최종결과', '', '', d.prgStatCd, true)
      this.listData.push(o)
      
      // 서비스콜
      // tmpStatus = (this.srvclExistYn === 'N') ? '해당없음' : ''
      // this.listData.push(this.fn_dataMake1(this.phclYmd, '서비스콜', '', tmpStatus, d.prgStatCd, true))
      o = this.fn_dataMake1(this.phclYmd.trim(), '서비스콜', '', '', d.prgStatCd, true)
      this.listData.push(o)

      o = this.fn_dataMake1(d.secusPblYmd, '증권발행', '', d.secusCvancMthCdNm, d.prgStatCd, true)
      // 증권발행
      this.listData.push(o)

      // 길이 오버 .... 표시 처리
      function strTurcate(dataObj){
        //길이 오버면 ...처리
        if(dataObj.data2 && dataObj.data2.length > 6){
          dataObj.data2 = String(dataObj.data2).substr(0,6) + '...'
        }else{
          dataObj.data3 = '' // Alert 안띄움 비교 값
        }
        return dataObj
      }
    },
    /******************************************************************************
    * Function명 : fn_dataMake1
    * 설명       : 진행현황 팝업 데이터 가공
    ******************************************************************************/
    fn_dataMake1 (ymd, title, content, status, prgStatCd, isStep, isSubContent , pendingStep='' ) {
      let o = {}
      o.date = this.fn_dateUtil(ymd) // 완료일자
      o.date = (o.date == '-')? '' : o.date

      o.data1 = title // 타이틀
      o.data2 = content // 상세내용
      o.data3 = content // 상세내용
      o.status = status // 상태 - 완료, 미심사, 해당없음 등등등
      o.isStep = isStep // 7단계 스탭에 포함되는지 여부 (청약접수, 입금, 신계약심사, 적부, 최종결과, 서비스콜, 증권발행)
      o.isDone = !(o.date === '' || o.date === '-') // 완료가 되었는지 여부
      o.isSubContent = isSubContent || !(o.date === '' || o.date === '-') // 완료일자 나오는 박스를 보여줄지 여부
      o.expandable = false // 내용이 길어서 상세를 보여줘야하는지 여부
      o.expand = false // 처음 진입 시 내용 상세를 열어줘야하는지 여부(리스트에서 선택된 데이터를 맵핑 해야하는데 어떤걸로 맵핑해야하는지 애매함)
      o.prgStatCd = prgStatCd // 진행상태코드
      // o.contStatCd = ''
      return o
    },
    /******************************************************************************
    * Function명 : fn_dateUtil
    * 설명       : 진행현황 날짜 유틸
    ******************************************************************************/
    fn_dateUtil (trtYmd) {
      let tmpStr = ''
      if (trtYmd === '') {
        tmpStr = '-'
      } else if (trtYmd.length === 8) {
        tmpStr = trtYmd.substr(0, 4) + '-' + trtYmd.substr(4, 2) + '-' + trtYmd.substr(6, 2)
      } else if (trtYmd.length === 10) {
        tmpStr = trtYmd
      } else if (trtYmd.length > 10) {
        tmpStr = trtYmd.substr(0, 10)
      }
      return tmpStr
    },
    /******************************************************************************
    * Function명 : fn_Confirm
    * 설명       : 확인버튼 선택 시 호출
    ******************************************************************************/
    fn_Confirm () {
      this.$emit('confirmPopup')
    }
  }
}
</script>
