/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS250M
 * 화면 설명: 가입설계관리 > 신계약진행현황 
             AS-IS TSSPS215D
 */
<template>
    <ur-page-container class="msp" type="subpage" title="신계약진행" ref="page" :show-title="true" :action-type="actionType" :topButton="true"
      @on-scroll-bottom="fn_ScrollBottomHandler"
      @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- Content영역 -->
    
    <template #frame-header-fixed>
      <ur-box-container direction="column" alignV="start" class="bgcolor-1 pa2024"> 
        <ul class="bullet-type-3 crTy-bk7 fs14rem">
          <li>신계약증권이 발행된 계약만 <b>[증권 재발행]</b>버튼이 활성화 됩니다.(*단체보험 기업주부담 계약 제외)</li>
        </ul>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title align-item-center">
              <span class="sum">총<span class="count">{{rsltCnt}}</span>건</span>
              <!-- 리스트 정렬 컴포넌트 -->
              <PSListSortOpt ref='sortFieldComp'
                            defaultLabel='정렬선택'
                            popupTitle="정렬선택"
                            itemValue="name" 
                            itemText="label"
                            :sortFieldList='sortFieldList'
                            @sortChange='fn_SortChangeHandler'/>
              <!--/ 리스트 정렬 컴포넌트 -->

              <!--
              <msp-bottom-select ref="bottomSelect" :items="items" v-model="value1" @input="onSelect" underline class="ns-dropdown-sheet maxW90 fs14rem type-1" placeholder="계약일" bottom-title="신계약진행 선택" closeBtn/>
              -->
            </div>
          </div>
        </mo-list-item> 
      </ur-box-container>
    </template>

    <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container direction="column" alignV="start" class="">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
          <ur-box-container alignV="start" componentid="" direction="column" class="" style="border-bottom: 0 none;">      

            <mo-list :list-data="rsltItemList" ref="moList">
              <template #list-item="{item,index}">      
                <mo-list-item class=""> 
                  <!-- expanded expand-only-btn -->
                  <msp-expand btn-area-first title-first expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" class="mo-list-expand w100" btn-area-class="fexTy3 mb0"  > 
                    <template #title>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title pt6">
                          <span class="name txtSkip fs19rem fwb">{{item.prdtNm}}</span>
                        </div>
                        <div class="mt10 mb4">
                          <span class="fs14rem mr6 crTy-bk7">피보험자</span> 
                          <span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 underline" @click="fn_GrdCustNmClick($event, item.insrdChnlCustId)">{{item.insrdNm}}</span>
                          <em class="em_normal">|</em>
                          <span class="fs14rem mr8 crTy-bk7">계약일</span>
                          <span class="crTy-bk1 fs16rem">{{item.contYmdLabel}}</span>
                        </div>
                        <div class="mb20">
                          <span class="fs14rem mr6 crTy-bk7">보험료</span> 
                          <span class="crTy-blue3 fs16rem fwb">{{$bizUtil.numberWithCommasCurr(item.smtotPrm, item.zaCrncyCd, 'Y', 'Y', 4)}}원</span>
                        </div>
                      </div>
                    </template>
                    <template #btn>
                      <mo-button class="link-arrow down mb76"></mo-button>
                    </template>
                    <template #content>
                      <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                        <ur-box-container class="con--padding ">
                          <div class="contents-row">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">계약자</span>
                              <span class="fs16rem underline" @click="fn_GrdCustNmClick($event, item.contrChnlCustId)">{{item.custNm}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">영수증번호</span>
                              <span class="fs16rem">{{item.planId}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">계약번호</span>
                              <span class="fs16rem">{{item.contNo}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">진행상태</span>
                              <span class="fs16rem underline" @click="fn_ContStatClick($event, item)">{{item.contStatCdNm}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">전산심사결과</span>
                              <span class="fs16rem underline" @click="fn_ShowMSPPS243P(item.planId)">{{item.lstCundwRsltCdNm}}</span>
                          </div>
                        </ur-box-container>
                      </div>
                    </template>
                  </msp-expand>
                </mo-list-item>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
                  <div class="ns-btn-relative-area mt0">
                    <div class="ns-btn-relative-s">
                      <div class="relative-div mb5">
                        <mo-button componentid="" class="ns-btn-round white ml0 mr0" @click="fn_MoveEntPlan(item)">계약조회</mo-button>
                        <mo-button :disabled="item.isPsbSecusRpbl == false" componentid="" class="ns-btn-round white ml0 mr0" @click="fn_MoveSecusRpbl(item)">증권재발행</mo-button>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- content 영역 -->

    <!-- NoData 영역 ur-box-containe  -->        
    <PSNodataMesgBoxForList v-else ref="nodataMesgBox"/>
    <!-- / NoData 영역 ur-box-containe -->
  </ur-page-container>

</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import MSPPS201P from '@/ui/ps/MSPPS201P'
import MSPPS242P from '@/ui/ps/MSPPS242P' //MSPPS013P
import MSPPS243P from '@/ui/ps/MSPPS243P' //MSPPS014P
import Screen from '~systems/mixin/screen'


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS250M', 
  screenId: 'MSPPS250M',
  mixins: [Screen],
  components: {
    'PSNodataMesgBoxForList'  : PSNodataMesgBoxForList, //데이터없음 메세지 출력 박스
    'PSListSortOpt'           : PSListSortOpt, //정렬 컴포넌트
    'PSHeaderSubMenuPopup'    : PSHeaderSubMenuPopup, // 서브메뉴 팝업
    'MSPPS201P'               : MSPPS201P, //검색 키워드 입력 팝업
    'MSPPS242P'               : MSPPS242P, //설계청약 신계약 진행현황 팝업
    'MSPPS243P'               : MSPPS243P  //전산심사결과 조회 팝업    
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{ 
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {    
    return {
      PSConstants         : PSConstants,  //PS상수
      actionType          : 'none', //상단 보조메뉴
      stndKey             : '', // 페이징 Next Key
      isNodata            : false, // 데이터 없음 Flag            
      rsltCnt             : 0 ,  // 조회결과 누적 데이터 길이
      rsltItemList        : [], // 조회결과 리스트 데이터
      sortFieldList       : [], //정렬 필드 옵션 리스트
      orgItems            : [], // 전체 리스트 목록 복사본
      searchPopup         : null, //검색팝업
      lv_popMSPPS242P     : null, //설계청약 신계약 진행현황 팝업
      lv_popMSPPS243P     : null, //전산심사결과 조회 팝업      
      isShowSearchPopup   : false, // 검색 팝업 활성여부
      isFCnGAUser         : false,
      rtnSearchPopupData  : {searchScVal : '', searchKeyword : ''}, // 검색팝업 리턴값
      //------------------------------------------------------------------------------
      __enddata : null
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    //로그인한 사용자가 FC 및 GA사용자인지 확인
    this.isFCnGAUser = this.$bizUtil.isFCnGAUser()
    
    //상단 Appbar 아이콘 검색 + 더보기 메뉴 아이콘 활성
    this.actionType = PSCommUtil.fn_SetAppBarSearchMoreMenu(this.fn_HeaderSerchHander,this.fn_MoreMenuHandler)
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    // 정렬옵션 필드 셋팅
    this.fn_SetOrderByFields()
    //초기 검색 조회 셋팅  
    this.rtnSearchPopupData.searchScVal   = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key
    this.rtnSearchPopupData.searchKeyword = ''
    this.fn_Reset()            
    this.fn_ServiceData('S')
    
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)


    //keepAlive 설정
    //this.$commonUtil.setRouteKeepAlive(this.$options.screenId, true); 

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

    // Life Cycle 인스턴스가 KeepAlive true 이고 다시 본 화면으로 접근시 Active (활성화) 된 후 호출 됨
  activated () {
    return
  },

  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 상단 타이틀 백 아이콘 클릭 핸드러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$router.push({ name: 'MSPBC002M' })
    },

    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화
    ******************************************************************************/
    fn_Reset: function () {
      this.stndKey = ''        
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
    },

    /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {
      let lv_Vm = this

      this.searchPopup = this.$bottomModal.open(MSPPS201P, {
        properties: {
          pParntSrnId     : lv_Vm.$options.screenId, // 부모 화면 ID
          pPopupTitle     : '신계약진행현황 검색',
          pSearchKeyword  : lv_Vm.rtnSearchPopupData.searchKeyword, //Default set Keyword Value
          pSearchScVal    : lv_Vm.rtnSearchPopupData.searchScVal    // 검색 구분 (1:고객명,2:상품명,3:영수증번호)       
        },
        
        listeners: {
          //대상팝업 onPopupReset $emit 이벤트명 backkey reset or reset
          onPopupReset : () => {
            lv_Vm.rtnSearchPopupData.searchScVal   = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key
            lv_Vm.rtnSearchPopupData.searchKeyword = ''
            lv_Vm.fn_Reset()            
            lv_Vm.fn_ServiceData('S')
            lv_Vm.$bottomModal.close(this.searchPopup) // 모달 닫기    
          },
          // 대상팝업 onPopupSearch $emit 이벤트명 
          onPopupSearch: (rtnData) => {
            console.log(rtnData)

            lv_Vm.rtnSearchPopupData.searchScVal = rtnData.searchScVal // 검색구분값
            lv_Vm.rtnSearchPopupData.searchKeyword = rtnData.searchKeyword // 검색키워드

            if (rtnData.searchKeyword !== '') {   //필터검색
              lv_Vm.fn_FltSearch(rtnData)
            }
            else {
              lv_Vm.fn_Reset()            
              lv_Vm.fn_ServiceData('S')
            }
            
            this.$bottomModal.close(this.searchPopup) // 모달 닫기                        
          }          
        }
      })

    },// fn_HeaderSerchHander

    /******************************************************************************
    * Function명  : fn_MoreMenuHandler
    * 설명        : 상단 더보기 메뉴 아이콘 클릭 핸들러
    ******************************************************************************/
    fn_MoreMenuHandler(){
      console.log('fn_MoreMenuHandler!!!')
      //팝업 열기 
      let subMenuModalId = this.$bottomModal.open(PSHeaderSubMenuPopup, {
        properties: {          
          parentSrnId  : this.$options.screenId, // 부모 화면 ID
          popupTitle   : '청약설계 업무 더보기',                    
          subMenuList  : PSCommUtil.fn_GetOfrPlanMoreMenuList(), //청약설계 보조메뉴 리스트
          closeBtn     : true 
        },
        listeners: {
          popupEventClose : () => {
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
          },
          popupEventRouterMove: (rtnData) => {//subMenuList에서 선택함 Item 리턴
            this.$bottomModal.close(subMenuModalId) // 모달 닫기            
          },      
        }
      },{properties: {closeBtn: true}})
    },

    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_ServiceData('N')
    },// fn_ScrollBottomHandler

    /******************************************************************************
    * Function명  : fn_SortReset
    * 설명        : 정렬 리셋 이벤트 핸들러
    ******************************************************************************/
    fn_SortReset(){
      console.log('fn_SortReset !!!')
      /*
      원본 되돌리기
      this.rsltItemList = []      
      this.orgItems.forEach(item => {
        this.rsltItemList.push(Object.assign({},item))
      });
      */
      
      //정렬 라벨표시 초기화
      this.$refs.sortFieldComp.fn_Reset()
    },

    /******************************************************************************
    * Function명  : fn_SetOrderByFields
    * 설명        : 정렬 컴포넌트 필드정의 셋팅
    *               화면에서 소트 컨트롤 방식 셋팅 fn_SortChangeHandler 에서 처리
    * 
    ******************************************************************************/
    fn_SetOrderByFields () {
        this.sortFieldList = [
           {field : 'contYmdSort'    , label : '계약일'    , sort: 'DESC'}
          ,{field : 'insrdNm'        , label : '피보험자'  , sort: 'ASC'}
          ,{field : 'custNm'         , label : '계약자'    , sort: 'ASC'}
        ]
    },
    /******************************************************************************
    * Function명  : fn_SortChangeHandler
    * 설명        : 정렬 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_SortChangeHandler (pSortOrder){
      console.log('fn_SortChangeHandler !!!')

      let sortField = pSortOrder.field
      let sortOrderby = pSortOrder.sort //ASC or DESC

      console.log(pSortOrder)
      console.log('sortField = '+sortField+ ' , sortOrderby = '+sortOrderby)
      
      switch(sortField){       
        case 'contYmdSort': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
          PSCommUtil.fn_SortListValueDateString(sortOrderby, this.rsltItemList, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
          break
        case 'insrdNm':  //String 형 sort
        case 'custNm':  //String 형 sort
          PSCommUtil.fn_SortListValueString(sortOrderby, this.rsltItemList, sortField)
          break
      }//switch

    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData(serviceName) {
     
     // 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
     if (serviceName === 'N' && this.stndKey === '') return

      let pParams = {}
      switch (serviceName) {
        case 'S': // 신계약진행 조회
          window.vue.getStore('progress').dispatch('SKEL_LIST')
        case 'N': // 신계약진행 더보기

          this.fn_SortReset()

          pParams.appId = 'sli.ipi'
          pParams.trnstId = 'txTSSPS21S5'
          pParams.fnctScCd = 'S'
          pParams.serviceId = 'C339_F1PQL0344_S'
          pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0344VO'
          pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0344VO'
          pParams.data = {}
          pParams.data.zNcontPrgPnstaIfDto = {}
          pParams.data.zNcontPrgPnstaIfDto.inqrScCd = '1' // 1:설계사 사번
          //pParams.data.zNcontPrgPnstaIfDto.clctCnsltNo = PSServiceManager.getUserInfo('userId') // FC 사번
          pParams.data.zNcontPrgPnstaIfDto.clctCnsltNo = '' // 모집컨설턴트번호 보안심의 대응 세션화 처리 
          pParams.data.zNcontPrgPnstaIfDto.pagingDto = {
            fnctScCd: serviceName,
            pageRowCnt: 30,
            stndKey: (serviceName === 'S') ? '' : this.stndKey
          }
          break
        default:
          break
      } // end switch

      pParams.srnId = this.$options.screenId // 호출 화면 명
      PSServiceManager.invokeSecuMskng(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)

    },

   /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data
      let stndKeyList = event.trnstComm.stndKeyList
      let that = this

      switch (serviceName) {
        case 'S': // 신계약진행 조회
          window.vue.getStore('progress').dispatch('PART')
        case 'N': // 신계약진행 더보기
          this.stndKey = lv_data.zncontPrgPnstaIfDto.pagingDto.stndKey
          let t_rslt
          if (lv_data.zncontPrgPnstaIfDto && lv_data.zncontPrgPnstaIfDto.contPrgStatListVO) {
            t_rslt = lv_data.zncontPrgPnstaIfDto.contPrgStatListVO
          }
          let t_rowIndex = this.rsltItemList.length
          if (t_rslt && t_rslt.length > 0) {
            t_rslt.forEach(pItem => {

              pItem.id = t_rowIndex++
              pItem.prdtNm = pItem.prdtNm.replace('삼성생명', '').replace('삼성', '').trim() // 설계명에 삼성생명, 삼성 제거 처리
              pItem.contYmdLabel = pItem.contYmd.substring(5, 10) // 2018-10-19 00:00:00.0
              pItem.contYmdSort = pItem.contYmd.substring(0, 10) // 2018-10-19 00:00:00.0
             

              //증권재발행 가능 여부 데이터셋팅
              // 계약상태코드 = '70'이고 증권발행일자 및 피보험자 존재시
              pItem.secusPblYmd = (pItem.secusPblYmd)? String(pItem.secusPblYmd).trim() : ''
              pItem.insrdNm = (pItem.insrdNm)? String(pItem.insrdNm).trim() : ''

              if (pItem.contStatCd === '70' && pItem.secusPblYmd && that.isFCnGAUser === true && pItem.insrdNm) {
                 pItem.isPsbSecusRpbl = true
              }else{
                 pItem.isPsbSecusRpbl = false
              }
              console.log(">>pItem.isPsbSecusRpbl = "+pItem.isPsbSecusRpbl)
              console.log(pItem)
              that.rsltItemList.push(pItem)
            })

            this.orgItems = this.rsltItemList
            
            this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
              for(let i = 0 ; i < this.orgItems.length; i++){
                this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
              }
            });
          } else {
            if (serviceName === 'S') this.fn_TableStat() // 조회결과 없음 처리
          }
   
          this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
          this.isNodata = (this.rsltCnt <= 0)? true : false

          /*
          if(this.rsltCnt > 0 && serviceName === 'S'){
            this.$nextTick(()=>{
              PSCommUtil.fn_MoListItemExpandIdx(this.$refs.moList,0,true)
            })
          }
          */
          break
        default:
          break
      } // end switch
      
    },

    /******************************************************************************
     * Function명 : fn_FltSearch
     * 설명 : 필터검색 - 팝업에서 넘어오는 함수
     ******************************************************************************/
    fn_FltSearch (pdata) {
      let tempArray = []
      this.orgItems.forEach(item => {
        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key) { // 고객명
          if ( pdata.chnlCustId === item.contrChnlCustId || pdata.chnlCustId === item.insrdChnlCustId) {
            tempArray.push(item)
          }
        }

        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key) { // 상품명
          if (item.prdtNm.indexOf(pdata.searchKeyword.trim()) > -1) {
            tempArray.push(item)
          }
        }

        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key) { // 영수증번호
          if (item.planId.indexOf(pdata.searchKeyword.trim()) > -1) {
            tempArray.push(item)
          }
        }
      })
      this.rsltItemList = []
      this.rsltItemList = tempArray

      this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
      this.isNodata = (this.rsltCnt <= 0)? true : false
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler(event, serviceName) {
      window.vue.getStore('progress').dispatch('PART')
      switch (serviceName) {
        case 'S':
          this.fn_TableStat()
          if (event && event.msgCd !== 'EFWS034') {
            this.getStore('confirm').dispatch('ADD', event.msgDesc)
          } else {
            // 다른 시스템 호출 간 오류가 발생하였습니다.
            this.getStore('confirm').dispatch('ADD', '데이터 없는 경우이니\n확인후 다시 시도하세요')
          } // end if          
          break
        case 'T':
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          break  
        default:
          break
      } // end switch
      
      this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0 
    },

    /************************************************************************************************
     * Function명  : fn_TableStat
     * 설명        : 데이터 없는 페이지 설정
     * 
     ************************************************************************************************/
    fn_TableStat() {
      this.isNodata = true
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
    },

 
  /*---------------------------------------------------------------------------------
  * 팝업 및 화면 이동 처리 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
     * Function명 : fn_GrdCustNmClick
     * 설명 : 테이블에 피보험자 컬럼을 선택했을때 nameUI 실행하는 함수
     ******************************************************************************/
    fn_GrdCustNmClick (event, pChnlCustId) {
      let nmUiObject = {
        chnlCustId : pChnlCustId, //고객채널 ID
        cnsltNo    : PSServiceManager.getUserInfo('userId'),  // FC 사번
        parentId   : this.$options.screenId //화면ID
      }      
      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId)=>{
        //Close 콜백 처리
        return
      })
    },

    /******************************************************************************
     * Function명 : fn_ContStatClick
     * 설명 : 설계청약 신계약 진행현황 팝업 호출하는 함수
     ******************************************************************************/
    fn_ContStatClick (event, item) {
      //event.stopPropagation()
      console.log('fn_ContStatClick !', item)
      this.lv_popMSPPS242P = this.$bottomModal.open(MSPPS242P, {
        properties: {
          contNo: item.contNoEncr //item.contNo
        },
        
        listeners: {
          // 대상팝업 onPopupSearch $emit 이벤트명 
          confirmPopup: (rtnData) => {
            console.log(rtnData)
            this.$bottomModal.close(this.lv_popMSPPS242P) // 모달 닫기                        
          }          
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_ShowMSPPS243P
     * 설명 : 전산심사결과 팝업 표시
     ******************************************************************************/
    fn_ShowMSPPS243P (planId) {
      console.log('fn_ShowMSPPS243P !', planId)
      this.lv_popMSPPS243P = this.$bottomModal.open(MSPPS243P, {
        properties: {
          viewPlanId: planId
        },
        listeners: {
          // 대상팝업 onPopupSearch $emit 이벤트명 
          closePopup: (rtnData) => {
            console.log(rtnData)
            this.$bottomModal.close(this.lv_popMSPPS243P) // 모달 닫기                        
          }          
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_MoveEntPlan
     * 설명 : 리스트 클릭 시 처리 함수 계약상세화면 연동
     ******************************************************************************/
    async fn_MoveEntPlan (item) {

      console.log('fn_MoveEntPlan!!',item)

     if ( item.contStatCd === '70' || item.contStatCd === '80') {
       // 복호화된 계약번호 
      let prcContNo   = await this.$commonUtil.getDecryptCall(this, [item.contNoEncr]) 
          prcContNo   = prcContNo[0]

      let contInfoObj = {
        viewId      : null,                         
        callerId    : this.$options.screenId,     
        chnlCustId  : item.contrChnlCustId,               
        cnsltNo     : PSServiceManager.getUserInfo('userId'),
        contNo      : prcContNo                       
      }
        // 설계상태코드 : 70 계약성립, 80 최종처리확인중        
        this.$router.push({name: 'MSPPM931M', params: contInfoObj})
      } else {
        this.getStore('confirm').dispatch('ADD', '청약중인 계약은 조회가 불가합니다.')
      }

    },

    /******************************************************************************
     * Function명 : fn_MoveSecusRpbl
     * 설명 : 2021.03.09 증권재발행화면 연계
     ******************************************************************************/
    fn_MoveSecusRpbl: function (item) {
      let routerParam = {name: 'MSPNB002M', params: {contrPartnerId: item.contrPartnerId, contrPgScCd: item.contrPgScCd}}
      PSCommUtil.fn_RoutepushSetKeepAlive(routerParam,false)      
      //alert("증권재발행화면 연계",item)
      //TO-DO 계약관리 개발후 링크 수정

      //this.$router.push({name: 'TSSNB911M', params: {contrPartnerId: this.slctItamData.contrPartnerId, contrPgScCd: this.slctItamData.contrPgScCd}})
    },
  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/


  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>