/*
 * 업무구분 : 전자서명
 * 화면 명 : MSPPS243P
 * 화면 설명: 설계청약 현황 > 전산심사 결과 팝업
              AS-IS TSSPS314P
 */
<template>

  <ur-page-container class="msp" title="전산심사" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container  direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container v-if="!isNodata" alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="listData">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info fexTy2">
                  <span class="fs14rem min70">심사대상</span>
                  <span class="crTy-bk1" >{{item.subject}}</span>
                </div>
                <!--
                <div class="list-item__contents__info fexTy2">
                  <span class="fs14rem min70">유형코드</span>
                  <span class="crTy-blue4">{{item.type}}</span>
                </div>
                <div class="list-item__contents__info fexTy2">
                  <span class="fs14rem min70">분류</span>
                  <span class="crTy-blue4">{{item.divison}}</span>
                </div>
                -->
                <div class="list-item__contents__info fexTy2">
                  <span class="fs14rem min70">체크항목</span>
                  <span class="crTy-blue4">{{item.check}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="fs14rem min70">가입금액</span>
                  <span class="crTy-bk1">{{$commonUtil.numberWithCommas(item.joinSum)}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="fs14rem min70">한도금액</span>
                  <span class="crTy-bk1">{{$commonUtil.numberWithCommas(item.limitSum)}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="fs14rem min70">초과금액</span>
                  <span class="crTy-bk1">{{$commonUtil.numberWithCommas(item.excessSum)}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- NoData 영역 ur-box-containe  -->        
      <PSNodataMesgBoxForList v-else ref="nodataMesgBox"/>        
      <!-- / NoData 영역 ur-box-containe -->  

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close">확인</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import Msg from '@/systems/webkit/msg/msg'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPPS243P',
  screenId: 'MSPPS243P',
  
  components: {
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList //데이터없음 메세지 출력 박스
  },//componets,
  
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    // 데이터 조회
    this.fn_init()
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

    // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  computed: {
  },
  watch: {
  },
  /***********************************************************************************
  * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    viewPlanId: {type: String}
  },

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      listData: [], // 리스트 데이터
      showPopup: true,
      isEmpty: false,
      isNodata: false // 데이터 없음 Flag
    }
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      this.fn_Close()
    },

    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 목록 초기조회
     ******************************************************************************/
    fn_init () {
      // 1. 전산심사결과 목록 초기화
      this.listData = []

      // 2. 전산심사결과 조회
      // txTSSPS21T1 : 전자서명 필요양식발행
      let pParams = {}
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS21S6'))
      pParams.data.zUndwRsltDtlIfDto = {}
      pParams.data.zUndwRsltDtlIfDto.planId = this.viewPlanId

      // 3. 서비스 호출
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, null, this.fn_ServiceFaultHandler)
    },
    /******************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출결과 함수
     ******************************************************************************/
    fn_ServiceResultHandler (event) {
      let lv_data = event.data.zundwRsltDtlIfDto
      if (lv_data.zundwRsltDtlListDto === undefined || lv_data.zundwRsltDtlListDto == null || lv_data.zundwRsltDtlListDto.length === 0) {
        this.listData = []
      } else {
        this.listData = []
        lv_data.zundwRsltDtlListDto.forEach(item => {
          // nrmYn이 Y인 경우 목록에서 제외 (EP전산심사결과 세부조회 > 비정상세부내용 화면 로직)
          if (item.nrmYn !== 'Y') {
            let listDataRow = {}
            // 특정 undwItmcd는 심사대상자 명칭변경 (EP전산심사결과 세부조회 > 비정상세부내용 화면 로직)
            if (item.undwItmCd === 'IAML001' || item.undwItmCd === 'IAML002' || item.undwItmCd === 'IAML003') {
              listDataRow.subject = '계약자'
            } else {
              listDataRow.subject = item.contvInsrdNm
            }
            listDataRow.type = item.undwItmCd
            listDataRow.divison = item.undwItmLclsfCd
            listDataRow.check = item.errCntnt
            listDataRow.joinSum = item.addupEntAmt
            listDataRow.limitSum = item.limtAmt
            listDataRow.excessSum = this.$bizUtil.numberWithCommasCurr(item.overAmt, item.crncyCd, 'N', 'N')

            // 202005 외화보험 - 달러보험일 경우, 초과금액은 달러 환산금액 추가 표기. 예: 300,000($3,000,000)
            if (item.crncyCd !== undefined && item.crncyCd === 'USD' && item.overAmtCnvl !== undefined) {
              listDataRow.excessSum += '\n (' + this.$bizUtil.numberWithCommasCurr(item.overAmtCnvl, item.crncyCd, 'N', 'Y', 4) + ')'
            }
            this.listData.push(listDataRow)
          }
        }) // lv_data.zundwRsltDtlListDto.forEach
      }

      this.isNodata = (this.listData <= 0)? true : false
    },
    /******************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 서비스 호출결과 함수
     ******************************************************************************/
    fn_ServiceFaultHandler (event) {
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 전산심사 화면 닫기
     ******************************************************************************/
    fn_Close () {
      this.$emit('closePopup')
    }
  }
}
</script>
