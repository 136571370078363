/*
 * 업무구분: 고객
 * 화 면 명: MSPCM129P
 * 화면설명: 소개자 고객조회
 * 작 성 일: 2022.06.23
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="false" title="고객검색" type="popup" @on-scroll-bottom="fn_LoadingData()" ref="page">
    <ur-box-container direction="column">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <!-- 상단고정 Tab UI -->
        <div class="ns-sliding-topbar">
          <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

            <!-- 헤더 타이틀 영역 -->
            <template #fixed="{scrollRate}">
              <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
                <div slot="nav" class="icon-wrapper" @click="fn_CloseModal()">
                  <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
                </div>
                {{scrollRate > 0.5 ? '고객검색' : '고객검색'}}
              </mo-top-bar>

              <!-- 고객명 검색영역 -->
              <div class="ns-input-box">
                <mo-text-field v-model="lv_SearchText" searchable underline placeholder="고객 이름 검색" @click-icon="fn_ClickSearchKeyword" @keyup.enter="fn_ClickSearchKeyword"/>
              </div>
              <!-- 총 건수 -->
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span class="sum">총 {{ lv_TotalCnt }}건</span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
            </template>

            <!-- collapsing 영역 -->
            <template #collapsing></template>

            <!-- stickey 영역 -->
            <template #scroll>
              <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-list-area">
                <ur-box-container v-if="lv_SearchFlg === 2" direction="column" alignV="start" componentid="ur_box_container_001" class="ns-radio-list2">
                  <mo-list :list-data="lv_Items">
                    <template #list-item="{ item }">
                      <mo-list-item>
                        <!-- 라디오 영역 -->
                        <mo-radio v-model="radio" :value="item.chnlCustId" @input="fn_CheckSelectedItem(item)" />
                        <!-- 컨텐츠 영역 -->
                        <div class="list-item__contents" @click="fn_CheckSelectedItem(item)" >
                          <div class="list-item__contents__title">
                            <!-- 고객명 -->
                            <span class="name">{{ item.custNm }}</span>
                            <!-- 고객구분 -->
                            <mo-badge class="badge-sample-badge blue" text="" shape="status">{{ item.chnlCustScCdNm }}</mo-badge>
                          </div>
                          <!-- 주민등록번호 | 성별 -->
                          <div class="list-item__contents__info">
                            <span>{{ $cmUtil.frmtKnbStr(item.knb) }}</span>
                            <em>|</em>
                            <span>{{ item.sxdsCdNm }}</span>
                          </div>
                          <!-- 주고객 | 관계 -->
                          <div class="list-item__contents__info">
                            <span>주고객 <span class="ml20">{{ item.mnCustNm }}</span></span>
                            <em>|</em>
                            <span>관계 <span class="ml20">{{ item.mnCustRltnCdNm }}</span></span>
                          </div>
                          <div class="list-item__contents__info">
                            <span v-if="item.celno.trim().length > 0">연락처 <span class="ml20">{{ item.celno }}</span></span>
                            <span v-else-if="item.emailAddr.trim().length > 0">연락처 <span class="ml20">{{ item.emailAddr }}</span></span>
                            <span v-else>연락처 </span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>

                <!-- NoData 영역  -->
                <ur-box-container v-else alignV="start" componentid="ur_box_container_005" direction="column" class="ns-check-list no-data">
                  <mo-list-item>
                    <div class="list-item__contents">
                      <div class="list-item__contents__info">
                        <span v-if="lv_SearchFlg === 0">고객명 입력 후 검색해 주세요.</span>
                        <span v-else-if="lv_SearchFlg === 1">검색결과가 존재하지 않습니다.</span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </ur-box-container>
            </template>
          </mo-collapsing-header-view>
        </div>
      <!-- 상단고정 Tab UI //-->
      </ur-box-container>

      <!-- 버튼 영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white"
              @click="fn_CloseModal()">취소</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"
              @click="fn_BtnK1Click()" :disabled="$bizUtil.isEmpty(radio)">확인</mo-button>
        </div>
      </ur-box-container>

      <div class="ns-height40"></div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM129P',
  // 현재 화면 ID
  screenId: 'MSPCM129P',
  // 컴포넌트 선언
  components: {},
  // 화면명
  props: {
    title: {
      type: String,
      default: '고객검색'
    },
    pCustNm: String // 채널고객명
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      radio: '',
      isScrolled: false,
      isEmptySearchKeyword: true,
      selectedItem: {}, // 선택값 저장
      // fdp-ininite loading으로 데이터를 조회하는지 여부
      lv_IsLoadingData: false,
      // 더 이상 조회할 데이터가 있는지 여부
      lv_MoreData: true,
      // 데이터가 응답 오기 전인지 여부
      lv_IsLoading: false,
      lv_Selected: null,
      lv_Items: [],
      // UI 처리 전용
      lv_SearchFlg: 0,

      // 페이징 처리 전용
      lv_TotalCnt: 0,
      lv_idx: 0,
      lv_pageCount: '',
      lv_SearchText: '',
      lv_NextKeyId: '',
      stndKeyList: [{stndKeyId: '', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 10}],
      pageRowCnt: 10, // 10
      // 서비스 조회 파라미터 (필요시 parameter 추가)
      lv_Params: {
        pageNo: 1,
        pageSize: 10 // 10
      },

      lv_HeaderviewScrollCompID: '',

      // 서비스 조회 데이터 객체(SVO) 처리 전용
      lv_CMCustInfoSVOs: []
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},

  /** watch 정의 영역 */
  watch: {
    lv_SearchText () {
      if (this.lv_SearchText.trim().length > 0) {
        this.isEmptySearchKeyword = false
        this.lv_SearchFlg = 2
      } else {
        this.isEmptySearchKeyword = true
        this.lv_SearchFlg = 0

        // 라디오 선택값 초기화
        this.radio = ''
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    if (!this.$bizUtil.isEmpty(this.pCustNm)) {
      this.lv_SearchText = this.pCustNm
      this.fn_Init()
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM129P')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_CheckSelectedItem
     * 설명       : row 선택 시 호출. 선택된 row의 radio value를 변경
     ******************************************************************************/
    fn_CheckSelectedItem (item) {
      // 선택값 저장
      this.selectedItem = item

      this.radio = item.chnlCustId
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 최초 로딩 시 전달 값을 확인하여 설정한다
     ******************************************************************************/
    fn_Init () {
      // 호출화면(부모)으로부터 전달받은 검색어(고객명) 설정
      this.fn_RetrieveListCustSrchData()
    },

    /******************************************************************************
     * Function명 : fn_RetrieveListCustSrchData
     * 설명       : 입력된 사용자 키워드에 의해 고객정보를 조회, 검색한다.
     * Params: cnsltNo: 컨설턴트 번호 (String) <필수>
     *         custNm: 고객명[검색키워드] (String) <필수>
     *
     * Return: 고객검색결과 목록 (List<CMCustInfoSVO>)
     ******************************************************************************/
    fn_RetrieveListCustSrchData () {
      //매니저가 컨설턴트를 변경했을경우
      if(this.$cmUtil.fn_GachangeTF()){
        this.lv_Params.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }else{
        this.lv_Params.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      }
      this.lv_Params.custNm = this.lv_SearchText // (고객명:검색키)

      // 서비스 호출 헤더데이터 전용
      var lv_Vm = this // (실행시) Vue객체모델 인스턴스
      const _trnstId = 'txTSSCM33S2' // 트랜잭션ID
      const _auth = 'S'

      this.post(lv_Vm, this.lv_Params, _trnstId, _auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_Vm.$commonUtil.isSuccess(response)) {
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              // 서비스 통신 조회키
              lv_Vm.stndKeyList[0] = response.trnstComm.stndKeyList[0]
              // 서버 데이터 가져오기
              lv_Vm.lv_CMCustInfoSVOs = response.body.iCCustInfoVO
              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
              lv_Vm.lv_Items = lv_Vm.lv_CMCustInfoSVOs.slice()
              // 검색 결과 목록 총 건수
              lv_Vm.lv_TotalCnt = lv_Vm.lv_CMCustInfoSVOs.length
              // 검색결과가 없음
              if (lv_Vm.lv_TotalCnt === 0) {
                lv_Vm.lv_SearchFlg = 1
              }
              if (response.body.iCCustInfoVO.length < lv_Vm.lv_Params.pageSize) {
                lv_Vm.lv_MoreData = false
              } else {
                lv_Vm.lv_MoreData = true
              }
              // 데이터가 응답(완료)되었으므로 false
              lv_Vm.lv_IsLoading = false
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              lv_Vm.lv_SearchFlg = 2
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_RetrieveMoreListCustSrchData
     * 설명       : 입력된 사용자 키워드에 의해 고객정보를 추가 조회, 검색한다.
     * Params: cnsltNo: 컨설턴트 번호 (String) <필수>
     *         custNm: 고객명[검색키워드] (String) <필수>
     *
     * Return: 고객검색결과 목록 (List<CMCustInfoSVO>)
     ******************************************************************************/
    fn_RetrieveMoreListCustSrchData () {
      //매니저가 컨설턴트를 변경했을경우
      if(this.$cmUtil.fn_GachangeTF()){
        this.lv_Params.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }else{
        this.lv_Params.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      }
      this.lv_Params.custNm = this.lv_SearchText // (고객명:검색키)

      // 서비스 호출 헤더데이터 전용
      var lv_Vm = this // (실행시) Vue객체모델 인스턴스
      const _trnstId = 'txTSSCM33S2' // 트랜잭션ID
      const _auth = 'S'

      this.post(lv_Vm, this.lv_Params, _trnstId, _auth)
        .then(function (response) {
          if ( lv_Vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              // 서비스 통신 조회키
              lv_Vm.stndKeyList[0] = response.trnstComm.stndKeyList[0]
              // 서버 데이터 가져오기
              lv_Vm.lv_CMCustInfoSVOs = lv_Vm.lv_CMCustInfoSVOs.concat(response.body.iCCustInfoVO)
              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
              lv_Vm.lv_Items = lv_Vm.lv_CMCustInfoSVOs.slice()
              // 검색 결과 목록 총 건수
              lv_Vm.lv_TotalCnt = lv_Vm.lv_CMCustInfoSVOs.length
              // 받은 데이터가 pageSize 보다 작으면, 더이상 조회할 데이터가 없음을 의미
              if (response.body.iCCustInfoVO.length < lv_Vm.lv_Params.pageSize) {
                lv_Vm.lv_MoreData = false
              }
              // 데이터가 응답(완료)되었으므로 false
              lv_Vm.lv_IsLoading = false
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              lv_Vm.lv_SearchFlg = 1
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : 인피니트 로딩데이터
     ******************************************************************************/
    fn_LoadingData () {
      this.lv_IsLoading = true
      if (this.lv_MoreData) { // 조회할 데이터가 더 있는지 확인
        this.lv_IsLoadingData = true // infinite 추가 로딩인지
        this.fn_RetrieveMoreListCustSrchData() // 데이터 조회 서비스 호출
        this.lv_IsLoadingData = false // infinite 추가 로딩 여부를 false로 초기화
      } else {
        this.$showSnackbar('더이상 조회할 데이터가 없습니다.')
      }
    },

    /******************************************************************************
     * Function명 : fn_ClickSearchKeyword
     * 설명       : 검색 키워드 입력 버튼 이벤트 핸들러
     ******************************************************************************/
     fn_ClickSearchKeyword () {
      // 초기화
      this.lv_Items = [] // 검색내역 리스트
      this.lv_CMCustInfoSVOs = []
      this.lv_TotalCnt = 0 // 총 건수
      this.radio = '' // 라디오 선택값

      if (!this.isEmptySearchKeyword) {
        this.lv_SearchFlg = 2
        this.fn_RetrieveListCustSrchData()
      } else {
        this.lv_SearchFlg = 0
      }
    },

    /******************************************************************************
     * Function명 : fn_BtnK1Click
     * 설명       : 선택한 고객정보를 호출한 화면으로 전달하고 종료
     ******************************************************************************/
    fn_BtnK1Click () {
      // 파라미터 들고 이동
      this.$emit('onPopupConfirm', this.selectedItem)
    },

    /******************************************************************************
     * Function명 : fn_CloseModal
     * 설명       : 취소 버튼
     ******************************************************************************/
    fn_CloseModal () {
      this.$emit('onPopupClose')
    }

  }
}
</script>
